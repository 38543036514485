import {createApp} from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.less'
import myZh from "@/assets/lang/zh.json";
import myEn from "@/assets/lang/en.json";
import zh from "view-ui-plus/dist/locale/zh-CN";
import en from "view-ui-plus/dist/locale/en-US";

const app = createApp(App)
let messages = {
    'zh': Object.assign(myZh, zh),
    'en': Object.assign(myEn, en)
}
app.use(store)
    .use(router)
    .use(ViewUIPlus)
    .mount('#app')
