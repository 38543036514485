<template>
  <div class="app">
    <Row justify="center" align="middle" :style="{height:innerHeight+'px'}">
      <Col :span="20">
        <div class="title">建设中</div>
        <div class="title">UNDER CONTRUCTION</div>
        <div class="remark">
          <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023045472号</a>
          <img src="./assets/logoA.png" style="width: 13px;margin-left: 20px;margin-right: 3px"/>
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33010502011758" rel="noreferrer" target="_blank">浙公网安备33010502011758</a>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      innerHeight: window.innerHeight,
    }
  },
  methods: {},
  mounted() {
  },
}
</script>

<style>
.app {
  min-width: 1200px;
}

.title {
  text-align: center;
  font-size: 50px;
}

.remark {
  text-align: center;
  margin-top: 50px;
}
</style>
